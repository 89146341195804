.header-text-1,
.header-text-2,
.header-text-3 {
    font-size: calc(5vw + 1rem);
    position: relative;
    width: max-content;
}

.header-text-1::before,
.header-text-1::after,
.header-text-2::before,
.header-text-2::after,
.header-text-3::before,
.header-text-3::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.header-text-1::before {
    background: white;
    animation: typewriter 3s steps(15) 1s forwards;
}

.header-text-1::after {
    background: transparent;
    width: 0.125em;
    animation:
        typewriter 3s steps(15) 1s forwards,
        blink 500ms steps(15) 0s 10 alternate;
}

.header-text-2::before {
    background: white;
    animation: typewriter 2s steps(10) 6s forwards;
}

.header-text-2::after {
    background: transparent;
    width: 0.125em;
    animation:
        typewriter 2s steps(10) 6s forwards,
        blink 500ms steps(10) 5s 10 alternate;
}

.header-text-3::before {
    background: white;
    animation: typewriter 1.5s steps(6) 11s forwards;
}

.header-text-3::after {
    background: transparent;
    width: 0.125em;
    animation:
        typewriter 1.5s steps(6) 11s forwards,
        blink 500ms steps(10) 10s infinite alternate;
}



@keyframes typewriter {
    to {
        left: 100%;
    }
}

@keyframes blink {
    to {
        background: black;
    }
}